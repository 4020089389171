import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import IntroPage from '../../../../components/ContentType/IntroPage/IntroPage';
import TextGraphTwoCol from '../../../../components/ContentType/TextGraphTwoCol/TextGraphTwoCol';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import PageBanner from '../../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../../components/Seo/Seo';
import Layout from '../../../../Layout';
import DocumentView from '../../../../Molecules/DocumentView/DocumentView';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { getLineChartConfigData, getLineColumnChartConfigData, getMultipleLineChartConfigData, formatBytes, getImage, getBreadCrumb, getDocument } from '../../../../Utils/Utils';

let classNames = require('classnames');

import '../engagements.scss';

const EngagementPreuve = ({ data }) => {
  const imagesArray = data?.allImages?.edges;
  const documentsArray = data.allDocuments.edges;
  const pageData = data?.pageData?.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);
  const [innerNavList, setInnerNavList] = useState([]);

  let sectionArr = [];
  let sectionCount = 0;
  let leftRightBlocks = [];
  let graphData = [];

  useEffect(() => {
    setInnerNavList(sectionArr);
  }, []);

  return (
    <Layout imagesArray={imagesArray}>
      <Seo title={pageData.title} description={pageData.field_sous_titre} />
      <div
        className={classNames('page_template', 'page_engagements')}
      >
        <ScrollPageNav
          data={innerNavList}
        />
        <PageBanner visuel={getImage(imagesArray, pageData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                //gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
                parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
                currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
                locale: pageData.langcode
              }}
            />
            <TitlePage
              color="color_white"
              title={pageData.title}
              description={pageData.field_sous_titre}
            />
          </div>
        </PageBanner>
        {pageData.relationships?.field_blocs?.map((block, i) => {
          if (block.field_title?.processed && pageData.field_progress) {
            sectionCount++;
            sectionArr.push({
              title: '0' + sectionCount + '. ' + block.field_title?.processed,
              section: 'scroll_to_' + sectionCount
            });
          }
          switch (block.__typename) {
            case 'block_content__block_texte':
              leftRightBlocks = [];
              block.relationships?.field_section?.forEach((item => {
                leftRightBlocks.push(
                  {
                    text: item.relationships?.field_bloc_texte[0]?.field_bloc_text_1.processed,
                    title: item.relationships?.field_bloc_texte[0]?.field_title?.processed,
                    visuel: getImage(imagesArray, item?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp,
                    arrowColor: item.relationships?.field_bloc_texte[0]?.field_theme_title
                  }

                );
              }));
              return (
                <div key={i}>

                  <IntroPage
                    customClass={'scroll_to_' + sectionCount}
                    title={block.field_title?.processed}
                    subtitle={block.field_subtitle?.processed}
                    visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                    text={block.body?.processed}
                    size="border" //wrapper
                  />
                  {leftRightBlocks.length > 0 && <section className={classNames('section_content')}>
                    <ShapeBackground
                      top={block.field_backgroud_color === 'ice' ? 'right' : block.field_border_top && 'left'}
                      color={block.field_backgroud_color === 'ice' ? 'catskill_white' : (block.field_backgroud_color === 'navy' ? 'dark_bleu' : 'white')}
                      bottomInfinite={block.field_infinite_border}
                      bottom={block.field_border_bottom_display}
                    >
                      <div className="wrapper_page_xs">
                        {leftRightBlocks.map((block, index) => (
                          <div key={index}>
                            <TextVisuelTwoCol
                              title={block.title}
                              orderInverse={true}
                              extremeRight={true}
                              textFullWidth={true}
                              text={block.text}
                              visuel={block.visuel}
                              visuelAspectRatioMobile={1.34241245}
                              visuelAspectRatioDesktop={1.04107142857}
                              visuelMaxHeightDesktop={560}
                            />
                          </div>
                        ))}
                      </div>
                    </ShapeBackground>
                  </section>}

                </div>
              );

            case 'block_content__block_document':
              let docArr = [];
              block.relationships?.field_file?.forEach((file => {
                docArr.push(
                  {
                    name: getDocument(documentsArray, file?.drupal_internal__mid)?.field_media_document?.description,
                    size: `(.pdf ${formatBytes(getDocument(documentsArray, file?.drupal_internal__mid)?.relationships?.field_media_document?.localFile.size)})`,
                    link: getDocument(documentsArray, file?.drupal_internal__mid)?.relationships?.field_media_document?.localFile.publicURL
                  }
                )
              }));
              return (
                < section className={classNames('section_content', 'section_nos_publications', 'scroll_to_' + sectionCount)} key={i}>
                  <div className="wrapper_page_xs">
                    <TitleSection title="Nos publications" type="arrow" />
                  </div>
                  <div className="wrapper_page">
                    <DocumentView
                      data={docArr}

                    />
                  </div>
                </section>
              );

            case 'block_content__block_graph_indicator':
              let gData = [];
              let datas = [];
              let Ydata = [];
              let Ytitle = [];
              block.relationships?.field_nos_indicateurs.forEach((indicator => {
                gData = [];
                Ydata = [];

                indicator.relationships?.field_highchart.forEach((highchart => {

                  datas = {};
                  highchart.relationships?.field_data.forEach((chartData => {
                    Ytitle = [];
                    switch (highchart.field_type) {
                      case "single_line":
                        let xAxisArr = [];
                        let yAxisArr = [];
                        highchart.field_x_axis_data?.forEach((x => {
                          xAxisArr.push(x)
                        }));
                        chartData.field_chart_data?.forEach((y => {
                          yAxisArr.push(y);
                        }));
                        highchart.relationships?.field_y_axis_data?.forEach((z => {
                          Ytitle.push(
                            {

                              title: z.field_title?.processed,

                            }
                          )
                        }))

                        Object.assign(datas,
                          {
                            title: highchart.field_title?.processed,
                            datas: getLineChartConfigData({
                              type: 'line',
                              xAxis_data: xAxisArr,
                              xAxis_title: highchart.field_title_x_axis,
                              yAxis_format: '{value} ' + highchart.relationships?.field_y_axis_data[0]?.field_subtitle,
                              yAxis_title: highchart.relationships?.field_y_axis_data[0]?.field_title?.processed,
                              series_data: [
                                {
                                  name: chartData.field_title?.processed,
                                  color: '#707070',
                                  lineColor: '#707070',
                                  data: yAxisArr,
                                  valueSuffix: chartData.field_value_suffixe,
                                  marker_fillColor: chartData.field_fill_color,
                                }
                              ],
                              tooltip_format: '<b>{point.percentage:.1f}</b> <strong>{point.y:,.0f} MT</strong>',

                            })
                          }
                        )
                        break;

                      case "column_line":
                        let xAxisArray = [];
                        let yAxisArray = [];
                        highchart.field_x_axis_data?.forEach((x => {
                          xAxisArray.push(x)
                        }));
                        chartData.field_chart_data?.forEach((y => {
                          yAxisArray.push(y);
                        }));

                        highchart.relationships?.field_y_axis_data?.forEach((z => {
                          Ytitle.push(
                            {
                              format: '{value} ' + z.field_subtitle,
                              title: z.field_title?.processed,
                              opposite: z.field_publish
                            }
                          )
                        }))


                        if (chartData.field_chart_type === "column") {
                          Ydata.push(
                            {
                              name: chartData.field_title?.processed,
                              type: 'column',
                              yAxis: 1,
                              data: yAxisArray,
                              tooltip: {
                                valueSuffix: chartData.field_value_suffixe
                              },
                              color: chartData.field_fill_color
                            }
                          );


                        }
                        else if (chartData.field_chart_type === "spine") {
                          Ydata.push(
                            {
                              name: chartData.field_title?.processed,
                              type: 'spline',
                              data: yAxisArray,
                              lineColor: '#707070',
                              tooltip: {
                                valueSuffix: chartData.field_value_suffixe
                              },
                              marker: {
                                enabled: true,
                                symbol: 'circle',
                                radius: 7.5,
                                fillColor: chartData.field_fill_color,
                                lineColor: '#707070',
                                lineWidth: 1
                              },
                            }
                          );
                        }

                        Object.assign(datas,
                          {
                            title: highchart.field_title?.processed,
                            datas: getLineColumnChartConfigData({
                              xAxis_data: xAxisArray,
                              xAxis_title: highchart.field_title_x_axis,
                              yAxis_data: Ytitle,
                              series_data: Ydata
                            })
                          }
                        )
                        break;

                      case "multiple_lines":
                        let xAxisA = [];
                        let yAxisAr = [];
                        highchart.field_x_axis_data?.forEach((x => {
                          xAxisA.push(x)
                        }));
                        chartData.field_chart_data?.forEach((y => {
                          yAxisAr.push(y);
                        }));

                        highchart.relationships?.field_y_axis_data?.forEach((z => {
                          Ytitle.push(
                            {

                              title: z.field_title?.processed,

                            }
                          )
                        }))

                        Ydata.push(
                          {
                            name: chartData.field_title?.processed,
                            data: yAxisAr,
                            color: '#707070',
                            valueSuffix: chartData.field_value_suffixe,
                            fillColor: chartData.field_fill_color
                          }
                        );

                        Object.assign(datas,
                          {
                            title: highchart.field_title?.processed,
                            datas: getMultipleLineChartConfigData({
                              type: 'line',
                              xAxis_data: xAxisA,
                              xAxis_title: highchart.field_title_x_axis,
                              lineColor: '#707070',
                              yAxis_data: Ytitle,
                              series_data: Ydata,
                              tooltip_format: '<b>{point.percentage:.1f}</b> <strong>{point.y:,.0f} MT</strong>',

                            })
                          }
                        )
                        break;
                    }
                  }));
                  gData.push(
                    datas
                  )
                }));

                graphData.push({
                  title: indicator.field_title?.processed,
                  text: indicator.field_text?.processed,
                  graphDatas: gData
                });

              }));
              return (
                <section className={classNames('section_content', 'section_our_indicators', 'scroll_to_' + sectionCount)}>
                  <ShapeBackground top="left" color="catskill_white" bottom="left">
                    <div className="wrapper_page_xs">
                      <TitleSection title="Nos indicateurs" type="arrow" />
                      {graphData.map((text_indicateure, index) => (
                        <div key={index}>
                          <TextGraphTwoCol
                            title={text_indicateure.title}
                            text={text_indicateure.text}
                            graphDatas={text_indicateure.graphDatas}
                          />
                        </div>
                      ))}
                    </div>
                  </ShapeBackground>
                </section>
              );
          }
        })}
      </div>
    </Layout>
  );
};
export const query = graphql`
query nosPreuvesTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!], $documentsID: [Int!]) {
  pageData: allNodePage(
    filter: {langcode: {eq: $locale}, path: {alias: {eq: $slug}}}
  ) {
    edges {
      node {
        title
        langcode
        field_type
        field_padding
        field_sous_titre
        path {
          alias
          langcode
        }
        relationships {
          field_image {
            drupal_internal__mid
          }
          field_blocs {
            __typename
            ... on Node {
              ... on block_content__block_texte {
                id
                field_title {
                  processed
                }
                body {
                  processed
                }
                field_subtitle {
                  processed
                }
                field_backgroud_color
                field_infinite_border
                field_border_top
                field_border_bottom_display
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                  field_section {
                    field_no_padding
                    relationships {
                      field_image {
                        drupal_internal__mid
                      }
                      field_bloc_texte {
                        field_theme_title
                        field_title {
                          processed
                        }
                        field_bloc_text_1 {
                          processed
                        }
                      }
                    }
                  }
                }
              }
              ... on block_content__block_document {
                field_title {
                  processed
                }
                relationships {
                  field_file {
                    drupal_internal__mid
                  }
                }
              }
              ... on block_content__block_graph_indicator {
                relationships {
                  field_nos_indicateurs {
                    relationships {
                      field_highchart {
                        field_title {
                          processed
                        }
                        field_title_x_axis
                        field_x_axis_data
                        relationships {
                          field_data {
                            field_chart_data
                            field_chart_type
                            field_fill_color
                            field_title {
                              processed
                            }
                            field_value_suffixe
                          }
                          field_y_axis_data {
                            field_title {
                              processed
                            }
                            field_subtitle
                            field_publish
                          }
                        }
                        field_type
                      }
                    }
                    field_title {
                      processed
                    }
                    field_text {
                      processed
                    }
                  }
                }
                field_title {
                  processed
                }
              }
            }
          }
        }
        body {
          processed
        }
      }
    }
  }

  allImages: allMediaImage(
    filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}
  ) {
    edges {
      node {
        drupal_internal__mid
        relationships {
          field_media_image {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                mobile: fixed(width: 456, height: 456) {
                  ...GatsbyImageSharpFixed
                }
                desktop: fixed(width: 1256, height: 814) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        langcode
      }
    }
  }

  allDocuments: allMediaDocument(filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $documentsID}}) {
    edges {
      node {
        langcode
        field_media_document {
          description
        }
        relationships {
          field_media_document {
            localFile {
              publicURL
              size
              name
            }
          }
        }
        drupal_internal__mid
      }
    }
  }

  allMenu :   allMenuLinkContentMenuLinkContent(
    filter: {langcode: {eq: $locale}}
  ) {
    edges {
      node {
        drupal_parent_menu_item
        drupal_id
        langcode
        link {
          url
        }
        menu_name
        title
      }
    }
  }
}
`;
export default EngagementPreuve;
